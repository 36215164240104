<ion-content class="s-a-content" *ngIf="template">

  <div class="item-details content-section header">
    <h5>{{item.name}}</h5>
    <ion-icon name="close" (click)="closeModal()"></ion-icon>
  </div>

  <div class="content-section add-ons" *ngIf="getActiveSections() as sections;">
    <div class="section" *ngFor="let section of sections; let i=index;">
      <h6>{{section.name}}</h6>
      <p class="select-txt">
        <span>
          {{getSelectOptionTxt(section)}}
        </span>
        <span class="required-txt" *ngIf="section.required">
          {{'SELECT_ADDON.required' | translate}}
        </span>
      </p>

      <ng-container [ngSwitch]="section.required && !section.multiple">
        <ng-container *ngSwitchCase="true">
          <ion-list>
            <ion-radio-group [value]="0" (ionChange)="onChangeOption($event, i)">
              <div class="section-option" *ngFor="let option of section.options; let j = index;" lines="none">
                <ion-label *ngIf="option.name !== 'Dressing: Custom-Cut (40rs/kg)'">{{option.name}}</ion-label>
                <textarea rows="2" *ngIf="option.name === 'Dressing: Custom-Cut (40rs/kg)'" type="text" [(ngModel)]="customName" placeholder="Dressing: Custom-Cut (40rs/kg)"></textarea>
                <div class="last-box" slot="end">
                  <ion-text>+{{option.price | currency: currencyCode:true}}</ion-text>
                  <ion-radio [value]="j"></ion-radio>
                </div>
              </div>
            </ion-radio-group>
          </ion-list>
        </ng-container>

        <ng-container *ngSwitchCase="false">
          <ion-list>
            <ion-item *ngFor="let option of section.options; let j = index;" lines="none">
              <ion-label>{{option.name}}</ion-label>
              <div slot="end">
                <ion-text>+{{option.price | currency: currencyCode:true}}</ion-text>
                <ion-checkbox (click)="selectOption(i, option, j)" [disabled]="isCheckboxDisabled(j, i)"></ion-checkbox>
              </div>
            </ion-item>
          </ion-list>
        </ng-container>


      </ng-container>
      <hr class="line" *ngIf="i !== sections.length - 1">
    </div>

  </div>

  <div class="add-btn content-section" *ngIf="getActiveSections()">
    <div class="price">
      {{item.price + getAddonsPrice() | currency: currencyCode:true}}
    </div>
    <ion-button (click)="addItems()" expand="block" class="footer-button">
      {{'SHARED.add_item' | translate}}
    </ion-button>
  </div>
</ion-content>