import { Component, OnInit } from '@angular/core';
import { Events, AlertController, LoadingController, NavController, NavParams, ModalController, ToastController } from '@ionic/angular';
import { Router, ActivatedRoute } from '@angular/router';
import { Storage } from '@ionic/storage';
import { UpiManualPaymentPage } from "../pages/upi-manual-payment/upi-manual-payment.page";
import { ConfigService } from '../services/config/config.service';
import { LabelService } from 'src/app/services/label/label.service';
import { CouponCodesService } from '../services/coupon-codes/coupon-codes.service';
import { UserService } from '../services/user/user.service';
import { CartService } from '../services/cart/cart.service';
import { CustomPaymentOptionPage } from '../pages/custom-payment-option/custom-payment-option.page';
import { AdminSettingsService } from '../services/admin-settings/admin-settings.service';
import { StripePage } from '../pages/payment-ui/stripe/stripe.page';
import { PaypalPage } from '../pages/payment-ui/paypal/paypal.page';
import { OrderService } from '../services/order/order.service';
import { SharedService } from '../services/shared/shared.service';

@Component({
  selector: 'app-order-payment',
  templateUrl: './order-payment.page.html',
  styleUrls: ['./order-payment.page.scss'],
})
export class OrderPaymentPage implements OnInit {
  phonepeNo: string = '';
  paytmNo: string = '';
  upiId: string = '';

  orderData: any;
  showLoader: boolean = true;
  totalPriceAfterDiscount: number = 0;
  loading: any;
  paytmActive: boolean = false;
  razorpayActive: boolean = false;
  razorpayId: any;
  orderAmount: number;
  orderId: any;
  userId: any;
  walletBalance: any;
  walletActive: boolean = true;
  minOrderAmntToUseWallet: number = 0;
  maxWalletAmntPerOrder: number = 1000;
  walletUsed: boolean = false;
  walletAmount: number = 0;
  envPaytmActive: boolean = false;
  cashbackAmount = 0;
  cashbackBalance = 0;
  upiManual = {
    active: false,
    upiId: '',
    qrCode: ''
  };
  currencyCode;
  taxType: string;
  isCod: boolean = false;
  SHARED_LABELS: any = {};
  ORDER_PAYMENT_LABELS: any = {};
  isCodAvailableForCoupon: boolean = true;
  codPercent = 100;
  partialPayment = {
    status: false,
    cod: 0,
    online: {
      amount: 0,
      completed: false
    }
  }
  paymentType = 'full'; // value: full || partial
  customOption = {
    active: false,
    name: '',
    details: '',
    image: {
        url: ''
    },
    textMandatory: false,
    imageMandatory: false
  }

  stripeData: any = {
    active: false
  };

  paymentGateways = ['paypal', 'cashfree'];
  paypalObj: any = {
    active: false
  };
  cashfreeObj: any = {
    active: false
  };
  ccAvenueObj: any = {
    active: false
  };

  extraCharge = {
    cash: {charge: 0, type: 'flat', chargeName: '', maxCharge: 0},
    razorpay: {charge: 0, type: 'flat', chargeName: '', maxCharge: 0},
    paytm: {charge: 0, type: 'flat', chargeName: '', maxCharge: 0},
    stripe: {charge: 0, type: 'flat', chargeName: '', maxCharge: 0},
    paypal: {charge: 0, type: 'flat', chargeName: '', maxCharge: 0},
    cashfree: {charge: 0, type: 'flat', chargeName: '', maxCharge: 0},
    ccAvenue: {charge: 0, type: 'flat', chargeName: '', maxCharge: 0},
}
  isGstApplicable: any;

  constructor(private events: Events,
              private router: Router,
              private alertController: AlertController,
              private loadingController: LoadingController,
              private route: ActivatedRoute,
              private storage: Storage,
              private navParams: NavParams,
              private modalController: ModalController,
              private navCtrl: NavController,
              private labelService: LabelService,
              private configService:ConfigService,
              private toastController: ToastController,
              private couponCodesService: CouponCodesService,
              private cartService: CartService,
              private userService: UserService,
              private adminSettingsService: AdminSettingsService,
              private orderService: OrderService,
              private sharedService: SharedService) {
                let orderId = navParams.get('orderId');
                let userId = navParams.get('userId');
                if (orderId !== undefined && userId !== undefined) {
                  this.orderId = orderId;
                  this.userId = userId;
                }
               }

  ngOnInit() {
    this.currencyCode = this.configService.environment.currencyCode;
    this.taxType = this.configService.environment.taxType;
  }
  ionViewDidEnter() {
    //this.envPaytmActive = environment.envPaytmActive;
    this.events.publish('user:getOrderDetailsWithOrderId', this.orderId);
    
    this.events.publish('wallet:getUserWalletDetails', this.userId);
    this.events.publish('wallet:getWalletSettings');
    this.initializeSubscriptions();
    this.SHARED_LABELS = this.labelService.labels['SHARED'];
    this.ORDER_PAYMENT_LABELS = this.labelService.labels['ORDER_PAYMENT'];

    this.getStripeDetails();

    this.getGateways();
    this.fetchIntegrationsCred();
  }
  ionViewWillLeave() {
    this.removeSubscription();
  }

  async fetchIntegrationsCred() {
    try {
      const integrationRes: any = await this.orderService.getIntegrationsCred('CCAvenue');
      console.log('integrationRes CCAvenue', integrationRes);
      if (Object.keys(integrationRes.credentials).length) {
        this.ccAvenueObj = integrationRes;
        this.ccAvenueObj['active'] = true;
      }
      else {
        this.ccAvenueObj['active'] = false;
      }
    }
    catch (e) {
      this.ccAvenueObj['active'] = false;
      console.error('fetchIntegrationsCred error', e);
    }
  }

  initializeSubscriptions() {
    this.events.subscribe('admin-settings:publishPaymentInfoData', (data) => {
      let isCod = typeof data.isCod !== 'undefined' ? data.isCod : true;
      let extraNoCodChecks = false;
      if(isCod) {
        for(const pdt of this.orderData[0].products) {
          if(pdt.hasOwnProperty('orderType') && pdt.orderType === 'membership' || ('isCod' in pdt && !pdt.isCod)) {
            extraNoCodChecks = true;
            break;
          }
        }
      }
      if(extraNoCodChecks || !this.isCodAvailableForCoupon) {
        isCod = false;
      }
      this.isCod = isCod;
      this.upiManual = data.hasOwnProperty('upiManual') ? data.upiManual: this.upiManual;
      this.codPercent = 'codPercentage' in data ? data.codPercentage : 100;
      this.partialPayment.status = this.codPercent < 100 ? true :  false;
      this.customOption = 'custom' in data ? data.custom : this.customOption;
      this.extraCharge.cash = 'extraCharge' in data ? data.extraCharge : this.getInitObjForExtraCharge();
      this.isGstApplicable = 'isGstApplicable' in data ? data.isGstApplicable : false;
    });

    this.events.subscribe('user:publishOrderDetailsWithOrderId', async (orderData) => {
      this.orderData = orderData;
      if(this.orderData[0].couponId) {
        this.isCodAvailableForCoupon = await this.couponCodesService.checkCODAvailabilityOfCoupon(this.orderData[0].couponId);
      }
      if('extraChargeOnPayment' in this.orderData && this.orderData[0].extraChargeOnPayment.charge) {
        this.orderData[0].extraChargeOnPayment = {charge: 0, type: 'flat', chargeName: '', maxCharge: 0}
      }
      this.events.publish('admin-settings:getPaytmData');
      this.events.publish('admin-settings:getRazorPayData');
      this.events.publish('admin-settings:getPaymentInfoData');
      this.showLoader = false;
    });
    this.events.subscribe('user:setPaymentModeOfOrderByUserSuccessfully', () => {
      this.loading.dismiss();
      this.presentAlert(`${this.ORDER_PAYMENT_LABELS['payment_mode_set_msg']}`);
    });
    this.events.subscribe('admin-settings:publishPaytmData', (data) => {
      if(data) {
        this.paytmActive = false;
        this.extraCharge.paytm = 'extraChargePaytm' in data ? data.extraChargePaytm : this.getInitObjForExtraCharge();
      }
    });
    this.events.subscribe('admin-settings:publishRazorPayData', (data) => {
      if(data && data.active && data.id !== '') {
        this.razorpayActive = data.active;
        this.razorpayId = data.id;
        this.extraCharge.razorpay = 'extraChargeRazorpay' in data ? data.extraChargeRazorpay : this.getInitObjForExtraCharge();
      }
    });
    this.events.subscribe('order:modeSetToCashSuccess', () => {
      this.loading.dismiss();
      this.presentAlert(`${this.ORDER_PAYMENT_LABELS['payment_mode_to_cash_msg']}`);
      this.modalDismiss();
    });
    this.events.subscribe('order:completePaymentWithWalletSuccess', () => {
      this.loading.dismiss();
      this.presentAlert(`${this.ORDER_PAYMENT_LABELS['payment_is_successful']}`);
    });
    this.events.subscribe('wallet:publishUserWalletDetails', (data) => {
      if(data) {
        this.walletBalance = data.wallet ? data.wallet.balance : 0;
        this.cashbackBalance = data.wallet && data.wallet.cashback && data.wallet.cashback > 0 ? data.wallet.cashback : 0;
      }
    });
    this.events.subscribe('wallet:publishWalletSettings', (data) => {
      if(!this.isEmptyObj(data)) {
        this.walletActive = typeof data.active !== 'undefined' ? data.active : true;
        this.minOrderAmntToUseWallet = data.minOrderAmnt ? data.minOrderAmnt : 0;
        this.maxWalletAmntPerOrder = data.maxWalletAmntPerOrder ? data.maxWalletAmntPerOrder : 1000;
      }
    });
  }

  async completePaymentWithWallet() {

    const alertRes = await this.sharedService.presentAlertConfirm(this.SHARED_LABELS['payment_mode_alert_msg'])
    if(!alertRes) {
        return;
    }

    const valid = await this.checkOrderUpdation();
    if(!valid) {
      return;
    }
    await this.presentLoading(1000000);
    this.orderData[0]['walletAmount'] = this.walletAmount;
    this.orderData[0]['cashbackAmount'] = this.cashbackAmount;
    this.events.publish('order:completePaymentWithWallet', this.orderData[0]);
  }

  isEmptyObj(object) {
    for(var key in object) {
      if(object.hasOwnProperty(key))
        return false;
    }
    return true;
  }

  onClickUseWallet() {
    if(this.walletUsed) {
      this.walletUsed = false;
      this.walletAmount = 0;
      this.cashbackAmount = 0;
    } else {
      this.getUsableWalletAmnt();
      this.walletUsed = true;
    }
  }

  async presentToast(msg: string) {
    const toast = await this.toastController.create({
      message: msg,
      duration: 2000,
    });
    toast.present();
  }

  getUsableWalletAmnt() {
    let walletUsed = 0;
    let cashbackUsed = 0;
    if(this.orderData[0].totalAmountToPaid < this.minOrderAmntToUseWallet) {
      walletUsed = this.orderData[0].totalAmountToPaid >= this.walletBalance ? this.walletBalance : this.orderData[0].totalAmountToPaid;
      this.walletAmount = walletUsed;
    } else {
      cashbackUsed = this.orderData[0].totalAmountToPaid >= this.cashbackBalance ? this.cashbackBalance : this.orderData[0].totalAmountToPaid;
      cashbackUsed = cashbackUsed > this.maxWalletAmntPerOrder ? this.maxWalletAmntPerOrder : cashbackUsed;
      this.cashbackAmount = cashbackUsed;
      if(this.orderData[0].totalAmountToPaid - this.cashbackAmount > 0) {
        walletUsed = (this.orderData[0].totalAmountToPaid - this.cashbackAmount) >= this.walletBalance ? this.walletBalance : (this.orderData[0].totalAmountToPaid - this.cashbackAmount);
        this.walletAmount = walletUsed;
      }
    }
  }

  getTotalItems() {
    return this.orderData[0].products.length;
  }

  modalDismiss(){
    this.modalController.dismiss()
  }

  async onClickPaymentMode(mode: string) {

    const alertRes = await this.sharedService.presentAlertConfirm(this.SHARED_LABELS['payment_mode_alert_msg'])
    if(!alertRes) {
        return;
    }

    const valid = await this.checkOrderUpdation();
    if(!valid) {
      return;
    }
    this.checkPartialPayment();
    
    this.orderData[0]['walletAmount'] = this.walletAmount;
    this.orderData[0]['cashbackAmount'] = this.cashbackAmount;

    if(mode === 'cash') {
      await this.presentLoading(10000);
      this.orderData['extraChargeOnPayment'] = this.getExtraChargeAmount(this.extraCharge.cash);
      this.events.publish('order:payWithCash',  this.orderData[0]);

    }
    if(mode === 'card' || mode === 'wallet' || mode === 'upi' || mode === 'netbanking') {
      await this.presentLoading(10000);
      this.orderData['extraChargeOnPayment'] = this.getExtraChargeAmount(this.extraCharge.razorpay);
      this.events.publish('order:payWithRazorPay', this.orderData[0], this.razorpayId, mode);
      this.modalDismiss();
    }
    if(mode === 'paytm') {
      await this.presentLoading();
      this.orderData['extraChargeOnPayment'] = this.getExtraChargeAmount(this.extraCharge.paytm);
      this.events.publish('order:payWithPaytm', this.orderData[0].orderId, this.userId, this.orderData[0].totalAmountToPaid);
      this.modalDismiss();
    }

    if (mode === 'upiManual') {
      this.modalDismiss();
      this.presentUPIModal(this.orderData[0], this.upiManual)
    }

    if (mode === 'custom') {
      this.modalDismiss();
      this.presentCustomOptionModal();
    }

    if (mode === 'stripe') {
      this.modalDismiss();
      this.orderData['extraChargeOnPayment'] = this.getExtraChargeAmount(this.extraCharge.stripe);
      this.presentStripeModal();
    }

    if (mode === 'paypal') {
      this.modalDismiss();
      this.orderData['extraChargeOnPayment'] = this.getExtraChargeAmount(this.extraCharge.paypal);
      this.presentPaypalModal();
    }

    if (mode === 'cashfree') {
        this.modalDismiss();
        this.orderData['extraChargeOnPayment'] = this.getExtraChargeAmount(this.extraCharge.cashfree);
        this.payWithCashfree();
    }

    if (mode === 'ccAvenue') {
      this.orderData['extraChargeOnPayment'] = this.getExtraChargeAmount(this.extraCharge.ccAvenue);
      if (!this.ccAvenueObj.paymentPage) {
        await this.sharedService.presentAlert('Payment link not found, please contact support!');
        return;
      }
      this.orderService.openCCAvenuePortal(this.orderData[0], this.ccAvenueObj.paymentPage, this.orderId);
    }

  }

  getExtraChargeAmount(extraChargeObj) {
    let extraCharge = 0;
    extraChargeObj = this.paymentType === 'partial' ? this.extraCharge.cash : extraChargeObj;
    if(Object.keys('extraChargeObj').length && extraChargeObj.charge) {
        if(extraChargeObj.type === 'flat') {
            extraCharge = extraChargeObj.charge;
        } else {
            extraCharge = (this.orderData[0].totalAmountToPaid - (this.orderData[0].walletAmount + this.orderData[0].cashbackAmount)) * (extraChargeObj.charge / 100);
            if(extraCharge > extraChargeObj.maxCharge) {
                extraCharge = extraChargeObj.maxCharge;
            }
        }
        this.orderData[0].totalAmountToPaid += extraCharge; 
        this.calcGst(extraCharge);
    }
    return {charge: extraCharge, name: extraChargeObj.chargeName || ''};
}

calcGst(extraCharge) {
    let allGst = [];
    let gstOnExtraCharge = 0;
    this.orderData[0].products.map((p) => {
        if (p.gst && this.isGstApplicable) {
            allGst.push(p.gst);}
    });
    if (allGst.length) {
        let minGst = 0;
        minGst = Math.min(...allGst);
        gstOnExtraCharge = (extraCharge - (extraCharge / (1 + (minGst / 100))));
        this.orderData[0].defaultGst += gstOnExtraCharge;
        this.orderData[0].deliveryGstObj.extraChargeGst = (this.orderData[0].deliveryGstObj.extraChargeGst || 0) + gstOnExtraCharge;
    }
}

  async presentUPIModal(order, upiManual) {
    const modal = await this.modalController.create({
    component: UpiManualPaymentPage,
    cssClass:'custom-modal',
    componentProps: {
      order: order,
      upiManual: upiManual }
    });
  
    await modal.present();
  
  }

  async presentCustomOptionModal() {
    const modal = await this.modalController.create({
    component: CustomPaymentOptionPage,
    cssClass:'custom-modal',
    componentProps: {
      order: this.orderData,
      customOption: this.customOption }
    });
  
    await modal.present();
  
  }
  
  async presentAlert(msg: string) {
    const alert = await this.alertController.create({
      message: msg,
      buttons: [{
        text: this.SHARED_LABELS['ok'],
        handler: () => {
            this.navCtrl.navigateRoot(['/']);
        }
      }]
    });
    await alert.present();
  }
  async presentLoading(duration?) {
    this.loading = await this.loadingController.create({
      message: this.SHARED_LABELS['please_wait'],
      duration: duration ? duration : 5000
    });
    await this.loading.present();
  }
  async presentFailureAlert(msg: string) {
    const alert = await this.alertController.create({
      message: msg,
      buttons: [`${this.ORDER_PAYMENT_LABELS['try_again']}`]
    });
    await alert.present();
  }

  showUnavailablePrice() {
    if(this.orderData[0].unavailablePrice && (this.orderData[0].hasOwnProperty('autoConfirmOrder') && (!this.orderData[0].autoConfirmOrder || (this.orderData[0].autoConfirmOrder && !this.orderData[0].payment.completed)))) {
      return true;
    } else {
      return false;
    }
  }

  checkPartialPayment() {
    const partial = {...this.partialPayment};
    if(this.paymentType === 'partial') {
      partial.status = true;
    } else {
      partial.status = false;
    }
    this.orderData[0]['partialPayment'] = partial;
  }

  setPaymentType(type: string) {
    this.paymentType = type;

    if(type === 'partial') {
      this.partialPayment['cod'] = parseFloat((this.getFinalAmount() * (this.codPercent / 100)).toFixed(2));
      this.partialPayment['online']['amount'] = parseFloat((this.getFinalAmount() - this.partialPayment['cod']).toFixed(2));
    }
  }

  getFinalAmount() {
    return this.orderData[0].totalAmountToPaid - (this.walletAmount + this.cashbackAmount);
  }

  async checkOrderUpdation() {
    await this.presentLoading(1000000000);
    const isProductsUpdated = (await this.cartService.compareCartWithUpdatedCart(this.orderData[0].products, 'order-summary')).cartUpdated;
    let isQtyAvailable = true;
    if (!isProductsUpdated) {
        isQtyAvailable = await this.cartService.inventoryManagement({products: this.orderData[0].products, orderId: this.orderData[0].id});
        if (isQtyAvailable) {
            this.loading.dismiss();
            return true;
        }
    }
    if (isProductsUpdated || !isQtyAvailable) {
        this.loading.dismiss();
        await this.cancelOrderAlert();
        return false;
    }
  }

  async cancelOrderAlert() {
    return new Promise(async (resolve) => {
      const alert = await this.alertController.create({
        message: this.ORDER_PAYMENT_LABELS['cart_updated_alert'],
        buttons: [{
          text: this.ORDER_PAYMENT_LABELS['cancel_and_create_new'],
          handler: async () => {
            await this.presentLoading();
            await this.userService.cancelOrderByUser(this.orderData[0].orderId, this.ORDER_PAYMENT_LABELS['cancelled_reason'], 'return')
            for (const product of this.orderData[0].products) {
                await this.cartService.addProductToCart(product);
            }
            this.loading.dismiss();
            this.modalDismiss();
            this.router.navigate(['user-cart']);
            resolve(true);
          }
        },{
          text: this.ORDER_PAYMENT_LABELS['cancel_order'],
          handler: async () => {
            await this.userService.cancelOrderByUser(this.orderData[0].orderId, this.ORDER_PAYMENT_LABELS['cancelled_reason'], 'return')
            this.modalDismiss();
            this.navCtrl.navigateRoot(['tabs/tabs/user-order-history']);
            resolve(true);
          }
        }]
      });
      await alert.present();
    });
  }

  async getStripeDetails() {
    this.stripeData = await this.adminSettingsService.getStripeData();
    this.extraCharge.stripe = 'extraChargeStripe' in this.stripeData ? this.stripeData.extraChargeStripe : this.getInitObjForExtraCharge();
  }

  async presentStripeModal() {
      const modal = await this.modalController.create({
          component: StripePage,
          cssClass:'custom-modal',
          componentProps: {
            orderData: this.orderData[0],
            stripeData: this.stripeData,
            autoConfirm: false
          }
      });

      await modal.present();
  }

  async getGateways() {
    for (const paymentGateway of this.paymentGateways) {
      let paymentGatewayData = await this.adminSettingsService.getPaymentGateway(paymentGateway);
      if (paymentGatewayData && paymentGatewayData.active) {
        if (paymentGateway == 'paypal') {
          this.paypalObj = paymentGatewayData;
          this.extraCharge.paypal = 'extraChargePaypal' in this.paypalObj ? this.paypalObj.extraChargePaypal : this.getInitObjForExtraCharge();
        }
        if (paymentGateway == 'cashfree') {
          this.cashfreeObj = paymentGatewayData;
          this.extraCharge.cashfree = 'extraChargeCashfree' in this.cashfreeObj ? this.cashfreeObj.extraChargeCashfree : this.getInitObjForExtraCharge();
        }
      }
    }
  }

  
  async presentPaypalModal() {
    const modal = await this.modalController.create({
        component: PaypalPage,
        cssClass:'custom-modal',
        componentProps: {
          orderData: this.orderData[0],
          autoConfirm: false,
          paypalObj: this.paypalObj
        }
    });
    await modal.present();
}

async payWithCashfree(){
    console.log('this.orderData:',this.orderData[0] );
    await this.orderService.createOrderCashfree(this.orderData[0], false);
}

getInitObjForExtraCharge() {
  return {charge: 0, type: 'flat', chargeName: '', maxCharge: 0};
}

  removeSubscription() {
    this.events.unsubscribe('user:publishOrderDetailsWithOrderId');
    this.events.unsubscribe('user:setPaymentModeOfOrderByUserSuccessfully');
    this.events.unsubscribe('admin-settings:publishPaytmData');
    this.events.unsubscribe('admin-settings:publishRazorPayData');
    this.events.unsubscribe('order:modeSetToCashSuccess');
    this.events.unsubscribe('wallet:publishUserWalletDetails');
    this.events.unsubscribe('wallet:publishWalletSettings');
    this.events.unsubscribe('order:completePaymentWithWalletSuccess');
    this.events.unsubscribe('admin-settings:publishPaymentInfoData');
  }
}
