<!-- <ion-header>
  <ion-toolbar>
    <ion-title>deliveryScheduleModal</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>

</ion-content> -->



<ion-list lines="none" class="ion-no-padding">
  <button (click)="closeModal()" class="cancelBTn"><i class="flaticon-null-19"></i></button>
  <h4 class="slotHead">Slot For Delivery</h4>
  <ion-spinner color="primary" id="hidethis"></ion-spinner>
  <h4 class="TimeHead" style="margin-top: -11px;">Select Date</h4>

  <div class="DateDiv">
    <div (click)="dateSelect(date)" class="delivery-date-time" [ngClass]="{'active' : selectedDate === date}"
      *ngFor="let date of scheduledDates">{{date | date}}
      <!-- <ion-select (ionChange)="selectDate($event)">
      <ion-select-option [value]="date" *ngFor="let date of scheduledDates">{{date | date}}</ion-select-option>
    </ion-select> -->
    </div>

  </div>


  <h4 class="TimeHead">Select a slot</h4>

  <div class="delivery-time" [ngClass]="{'deactive' : !selectedDate}">
    <ng-container *ngFor="let time of timeSchedules">
      <div *ngIf="time.show" [ngClass]="{'deactiveTime' : !disableTime(time), 'activeTime': selectedTime === time && !disableTime(time)}"
        class="delivery-time-btn" (click)="timeSelect(time)" >{{time.start}} -
        {{time.end}}</div>
    </ng-container>
  </div>

  <ion-button (click)="done()" class="DoneBTn">Done</ion-button>

  <!-- <ion-item class="delivery-date-time">
    <ion-label>Select Time:</ion-label>
    <ion-select (ionChange)="selectTime($event)" [disabled]="!selectedDate">
      <ion-select-option [value]="time" *ngFor="let time of timeSchedules" [disabled]="disableTime(time)">
        {{time.start}} - {{time.end}}
      </ion-select-option>
    </ion-select>
  </ion-item> -->
</ion-list>