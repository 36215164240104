import * as tslib_1 from "tslib";
import { OnInit, ElementRef } from '@angular/core';
import { AlertController, LoadingController, Events, Platform, ModalController, NavParams } from '@ionic/angular';
import { Router, ActivatedRoute } from '@angular/router';
import { UserService } from '../services/user/user.service';
import { StatesModalPage } from '../states-modal/states-modal.page';
import { LabelService } from 'src/app/services/label/label.service';
import { ConfigService } from '../services/config/config.service';
import { MultiCountriesService } from '../services/multi-countries/multi-countries.service';
// import {    } from '@angular/core';
import { DeliveryService } from 'src/app/services/delivery/delivery.service';
var NewAddressPage = /** @class */ (function () {
    function NewAddressPage(events, router, alertController, loadingController, route, userService, platform, navParams, configService, multiCountriesService, modalController, deliveryService, labelService) {
        this.events = events;
        this.router = router;
        this.alertController = alertController;
        this.loadingController = loadingController;
        this.route = route;
        this.userService = userService;
        this.platform = platform;
        this.navParams = navParams;
        this.configService = configService;
        this.multiCountriesService = multiCountriesService;
        this.modalController = modalController;
        this.deliveryService = deliveryService;
        this.labelService = labelService;
        this.addressObj = {
            name: '',
            address: '',
            city: '',
            state: '',
            stateCode: '',
            pincode: '',
            phoneNo: '',
            createdAt: null,
            defaultAddress: true,
            lat: null,
            lng: null,
            country: '',
            flatNo: '',
            floor: '',
            landmark: ''
        };
        this.routeFromCheckoutPage = false;
        this.routeFromSelectAddress = false;
        this.routeFromMyAddresses = false;
        // By default google headquarters
        this.lat = 37.419857;
        this.lng = -122.078827;
        this.states = [];
        this.addressType = 'shipping';
        this.SHARED_LABELS = {};
        this.NEW_ADDRESS_LABELS = {};
        this.countries = [];
        this.pageType = 'page';
        if (navParams.get('addressData')) {
            this.editAddressData = navParams.get('addressData');
        }
        if (navParams.get('addressLength')) {
            this.addressLength = navParams.get('addressLength');
        }
        if (navParams.get('routeFromCheckoutPage')) {
            this.routeFromCheckoutPage = navParams.get('routeFromCheckoutPage');
        }
        if (navParams.get('routeFromSelectAddress')) {
            this.routeFromSelectAddress = navParams.get('routeFromSelectAddress');
        }
        if (navParams.get('routeFromMyAddresses')) {
            this.routeFromSelectAddress = navParams.get('routeFromMyAddresses');
        }
        if (navParams.get('type')) {
            this.addressType = navParams.get('type');
        }
    }
    NewAddressPage.prototype.ngOnInit = function () {
        var _this = this;
        this.SHARED_LABELS = this.labelService.labels['SHARED'];
        navigator.geolocation.getCurrentPosition(function (position) {
            console.log('position:', position);
            _this.lat = position.coords.latitude;
            _this.lng = position.coords.longitude;
            setTimeout(function () {
                _this.loadMap();
            }, 500);
        }, function (error) {
            setTimeout(function () {
                _this.loadMap();
            }, 500);
            console.log('error:', error);
        });
    };
    NewAddressPage.prototype.ionViewDidEnter = function () {
        this.initializeSubscriptions();
        this.addressObj.phoneNo = this.userService.getPhoneNo();
        this.SHARED_LABELS = this.labelService.labels['SHARED'];
        this.NEW_ADDRESS_LABELS = this.labelService.labels['NEW_ADDRESS'];
        this.countries = this.multiCountriesService.multiCountriesSettings.countries;
    };
    NewAddressPage.prototype.ionViewDidLeave = function () {
        this.removeSubscriptions();
    };
    NewAddressPage.prototype.presentLoading = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.loadingController.create({
                                message: this.SHARED_LABELS['please_wait'],
                                duration: 10000
                            })];
                    case 1:
                        _a.loading = _b.sent();
                        return [4 /*yield*/, this.loading.present()];
                    case 2:
                        _b.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    NewAddressPage.prototype.loadMap = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var latLng, mapOptions, marker, content, infoWindow;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        console.log('lat:', this.lat, 'lng:', this.lng);
                        return [4 /*yield*/, new google.maps.LatLng(this.lat, this.lng)];
                    case 1:
                        latLng = _a.sent();
                        console.log('latLng obj:', latLng);
                        mapOptions = {
                            center: latLng,
                            zoom: 15,
                            scrollwheel: false,
                            mapTypeId: google.maps.MapTypeId.ROADMAP
                        };
                        this.map = new google.maps.Map(this.mapElement.nativeElement, mapOptions);
                        marker = new google.maps.Marker({
                            map: this.map,
                            animation: google.maps.Animation.DROP,
                            draggable: true,
                            position: this.map.getCenter()
                        });
                        this.marker = marker;
                        content = "<p><b>Order will be delivered hare</b></p><p>Place the pin accurately on the map</p>";
                        infoWindow = new google.maps.InfoWindow({
                            content: content
                        });
                        infoWindow.open(this.map, marker);
                        new google.maps.event.addListener(this.marker, 'dragend', function (event) {
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    NewAddressPage.prototype.saveAddress = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var latLngObj, address, formatted_address;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.presentLoading();
                        latLngObj = {
                            lat: this.marker.getPosition().lat(),
                            lng: this.marker.getPosition().lng()
                        };
                        return [4 /*yield*/, this.deliveryService.getAddressFromLatLng(latLngObj)];
                    case 1:
                        address = _a.sent();
                        formatted_address = address.results[0].formatted_address;
                        this.loading.dismiss();
                        this.modalController.dismiss(formatted_address);
                        return [2 /*return*/];
                }
            });
        });
    };
    NewAddressPage.prototype.modalDismiss = function () {
        this.modalController.dismiss();
    };
    NewAddressPage.prototype.initializeSubscriptions = function () {
        var _this = this;
        this.events.subscribe('user:newAddressSaved', function () {
            _this.loading.dismiss();
            if (_this.routeFromCheckoutPage === true || _this.routeFromSelectAddress === true) {
                _this.modalDismiss();
                _this.router.navigate(['order-summary']);
            }
            else {
                if (_this.pageType === 'modal') {
                    _this.modalController.dismiss({ close: false });
                }
                else {
                    _this.presentAlert("" + _this.NEW_ADDRESS_LABELS['address_saved_successfully'], true);
                }
            }
        });
        this.events.subscribe('user:addressEditSuccess', function () {
            _this.loading.dismiss();
            _this.modalDismiss();
            if (_this.routeFromSelectAddress === true) {
                _this.router.navigate(['order-summary']);
            }
            else {
                if (_this.pageType === 'modal') {
                    _this.modalController.dismiss({ close: false });
                }
                else {
                    _this.presentAlert("" + _this.NEW_ADDRESS_LABELS['address_edited_successfully'], true);
                }
            }
        });
        this.events.subscribe('user:errorInGettingAddress', function () {
            _this.loading.dismiss();
            _this.presentAlert("" + _this.NEW_ADDRESS_LABELS['error_in_getting_address']);
        });
        this.events.subscribe('user:addressValueFromLatLng', function (response) {
            _this.loading.dismiss();
            //// console.log('address', response);
            _this.getAddressFromResponse(response.results[0]);
        });
    };
    NewAddressPage.prototype.getAddressFromResponse = function (data) {
        if (!this.editAddressData) {
            this.addressObj.address = data.formatted_address;
            this.addressFromApi = data.formatted_address;
            for (var index = 0; index < data.address_components.length; index++) {
                for (var x = 0; x < data.address_components[index].types.length; x++) {
                    if (data.address_components[index].types[x] === "postal_code") {
                        this.addressObj.pincode = data.address_components[index].long_name;
                    }
                }
            }
        }
        else {
            this.editAddressData.address = data.formatted_address;
            this.addressFromApi = data.formatted_address;
            for (var index = 0; index < data.address_components.length; index++) {
                for (var x = 0; x < data.address_components[index].types.length; x++) {
                    if (data.address_components[index].types[x] === "postal_code") {
                        this.editAddressData.pincode = data.address_components[index].long_name;
                    }
                }
            }
        }
    };
    NewAddressPage.prototype.onClickSaveAddress = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.addressObj.name = this.addressObj.name.trim();
                        this.addressObj.city = this.addressObj.city.trim();
                        if (!(this.addressObj.name === '')) return [3 /*break*/, 1];
                        this.presentAlert("" + this.NEW_ADDRESS_LABELS['please_enter_name']);
                        return [3 /*break*/, 9];
                    case 1:
                        if (!(this.addressObj.address === '')) return [3 /*break*/, 2];
                        this.presentAlert("" + this.NEW_ADDRESS_LABELS['please_enter_address']);
                        return [3 /*break*/, 9];
                    case 2:
                        if (!(this.addressObj.city === '')) return [3 /*break*/, 3];
                        this.presentAlert("" + this.NEW_ADDRESS_LABELS['please_enter_city']);
                        return [3 /*break*/, 9];
                    case 3:
                        if (!(this.addressObj.state === '')) return [3 /*break*/, 4];
                        this.presentAlert("" + this.NEW_ADDRESS_LABELS['please_enter_state']);
                        return [3 /*break*/, 9];
                    case 4:
                        if (!(this.addressObj.pincode === '')) return [3 /*break*/, 5];
                        this.presentAlert("" + this.NEW_ADDRESS_LABELS['please_enter_pincode']);
                        return [3 /*break*/, 9];
                    case 5:
                        if (!(this.addressObj.flatNo === '')) return [3 /*break*/, 6];
                        this.presentAlert('please enter Flat No');
                        return [3 /*break*/, 9];
                    case 6:
                        if (!(this.addressObj.phoneNo === '')) return [3 /*break*/, 7];
                        this.presentAlert("" + this.NEW_ADDRESS_LABELS['please_enter_phoneno']);
                        return [3 /*break*/, 9];
                    case 7: return [4 /*yield*/, this.presentLoading()];
                    case 8:
                        _a.sent();
                        this.addressObj.createdAt = new Date();
                        if (this.addressFromApi === this.addressObj.address) {
                            this.addressObj.lat = this.latitude;
                            this.addressObj.lng = this.longitude;
                        }
                        this.events.publish('user:saveNewAddress', this.addressObj, this.addressType);
                        _a.label = 9;
                    case 9: return [2 /*return*/];
                }
            });
        });
    };
    NewAddressPage.prototype.onClickEditAddress = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.editAddressData.name = this.editAddressData.name.trim();
                        this.editAddressData.city = this.editAddressData.city.trim();
                        if (!(this.editAddressData.name === '')) return [3 /*break*/, 1];
                        this.presentAlert("" + this.NEW_ADDRESS_LABELS['please_enter_name']);
                        return [3 /*break*/, 9];
                    case 1:
                        if (!(this.editAddressData.address === '')) return [3 /*break*/, 2];
                        this.presentAlert("" + this.NEW_ADDRESS_LABELS['please_enter_address']);
                        return [3 /*break*/, 9];
                    case 2:
                        if (!(this.editAddressData.city === '')) return [3 /*break*/, 3];
                        this.presentAlert("" + this.NEW_ADDRESS_LABELS['please_enter_city']);
                        return [3 /*break*/, 9];
                    case 3:
                        if (!(this.editAddressData.state === '')) return [3 /*break*/, 4];
                        this.presentAlert("" + this.NEW_ADDRESS_LABELS['please_enter_state']);
                        return [3 /*break*/, 9];
                    case 4:
                        if (!(this.editAddressData.pincode === '')) return [3 /*break*/, 5];
                        this.presentAlert("" + this.NEW_ADDRESS_LABELS['please_enter_pincode']);
                        return [3 /*break*/, 9];
                    case 5:
                        if (!(this.editAddressData.pincode === '')) return [3 /*break*/, 6];
                        this.presentAlert("" + this.NEW_ADDRESS_LABELS['please_enter_pincode']);
                        return [3 /*break*/, 9];
                    case 6:
                        if (!(this.editAddressData.flatNo === '')) return [3 /*break*/, 7];
                        this.presentAlert('please enter Flat No');
                        return [3 /*break*/, 9];
                    case 7: return [4 /*yield*/, this.presentLoading()];
                    case 8:
                        _a.sent();
                        this.editAddressData.createdAt = new Date();
                        if (this.addressFromApi === this.editAddressData.address) {
                            this.editAddressData.lat = this.latitude;
                            this.editAddressData.lng = this.longitude;
                        }
                        this.events.publish('user:editSavedAddress', this.editAddressData, this.addressType);
                        _a.label = 9;
                    case 9: return [2 /*return*/];
                }
            });
        });
    };
    NewAddressPage.prototype.updateNewAddressDefaultStatus = function () {
        if (this.addressObj.defaultAddress === true || this.addressObj.defaultAddress === null) {
            this.addressObj.defaultAddress = false;
        }
        else {
            this.addressObj.defaultAddress = true;
        }
    };
    NewAddressPage.prototype.updateEditAddressDefaultStatus = function (status) {
        if (status === true) {
            this.editAddressData.status = false;
        }
        else {
            this.editAddressData.status = true;
        }
    };
    NewAddressPage.prototype.goToPage = function (page) {
        this.router.navigate([page]);
    };
    NewAddressPage.prototype.addressChange = function () {
        this.editAddressData.lat = null;
        this.editAddressData.lng = null;
    };
    NewAddressPage.prototype.openStateModal = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var modal;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.modalController.create({
                            component: StatesModalPage,
                            cssClass: 'custom-modal'
                        })];
                    case 1:
                        modal = _a.sent();
                        modal.onDidDismiss()
                            .then(function (res) {
                            //// console.log('data from modal', res);
                            if (res.data) {
                                //// console.log(res.data);
                                if (!_this.editAddressData) {
                                    _this.addressObj.state = res.data.state;
                                    _this.addressObj.stateCode = res.data.code;
                                }
                                else {
                                    _this.editAddressData.state = res.data.state;
                                    _this.editAddressData.stateCode = res.data.code;
                                }
                            }
                        });
                        return [4 /*yield*/, modal.present()];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    NewAddressPage.prototype.presentAlert = function (msg, action) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var alert;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.alertController.create({
                            message: msg,
                            buttons: [{
                                    text: 'Ok',
                                    handler: function () {
                                        //// console.log('Confirm Okay');
                                        if (action === true) {
                                            _this.router.navigate(['user-addresses']);
                                            _this.modalDismiss();
                                        }
                                    }
                                }]
                        })];
                    case 1:
                        alert = _a.sent();
                        return [4 /*yield*/, alert.present()];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    NewAddressPage.prototype.isPincodeValid = function () {
        var pincode = this.editAddressData ? this.editAddressData.pincode : this.addressObj.pincode;
        if (this.configService.environment.currencyCode === 'INR' && pincode.length && pincode.length < 6) {
            return false;
        }
        else {
            return true;
        }
    };
    NewAddressPage.prototype.getPincodeHeading = function () {
        if (this.configService.environment.currencyCode === 'INR') {
            return 'NEW_ADDRESS.pincode';
        }
        else {
            return 'NEW_ADDRESS.state_area_code';
        }
    };
    NewAddressPage.prototype.closeModal = function () {
        this.modalController.dismiss({ closed: true });
    };
    NewAddressPage.prototype.removeSubscriptions = function () {
        this.events.unsubscribe('user:newAddressSaved');
        this.events.unsubscribe('user:addressEditSuccess');
        this.events.unsubscribe('user:errorInGettingAddress');
        this.events.unsubscribe('user:addressValueFromLatLng');
    };
    // async showMapModal(){
    //   const modal = await this.modalController.create({
    //   component: MapPage,
    //   cssClass:'custom-modal'
    //   });
    //   modal.onDidDismiss()
    //   .then((res) => {
    //     if(res.data) {
    //       if(!this.editAddressData){
    //         console.log('address:', res.data);
    //         this.addressObj.address = res.data;
    //       } else if(this.editAddressData){
    //         this.editAddressData.address = res.data;
    //       }
    //     }
    // });
    //   await modal.present();
    // }
    // askLocationPermission(){
    //   navigator.geolocation.getCurrentPosition((position) => {
    //     console.log('position:', position);
    //     // this.lat = position.coords.latitude;
    //     // this.lng = position.coords.longitude;
    //     // setTimeout(() => {
    //     //   this.loadMap();
    //     // }, 500);
    //   })
    // }
    NewAddressPage.prototype.onChangeCountry = function (e) {
        this[this.editAddressData ? 'editAddressData' : 'addressObj'].country = e.target.value;
    };
    NewAddressPage.prototype.phoneNumberValidation = function (event, type) {
        var pattern = /[0-9.,+]/;
        var inputChar = String.fromCharCode(event.charCode);
        if (type === 'new') {
            if (!pattern.test(inputChar) || this.addressObj.phoneNo.length >= 13) {
                event.preventDefault();
            }
        }
        else if (type === 'edit') {
            if (!pattern.test(inputChar) || this.editAddressData.phoneNo.length >= 13) {
                event.preventDefault();
            }
        }
    };
    NewAddressPage.prototype.textValidation = function (event) {
        var pattern = /[a-zA-Z]+$/;
        var inputChar = String.fromCharCode(event.charCode);
        if (!pattern.test(inputChar)) {
            // invalid character, prevent input
            event.preventDefault();
        }
    };
    return NewAddressPage;
}());
export { NewAddressPage };
