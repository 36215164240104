import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ConfigService } from 'src/app/services/config/config.service';
import { LabelService } from 'src/app/services/label/label.service';
import { SharedNewService } from 'src/app/services/shared-new/shared-new.service';

@Component({
  selector: 'app-select-addon',
  templateUrl: './select-addon.page.html',
  styleUrls: ['./select-addon.page.scss'],
})
export class SelectAddonPage implements OnInit {

  template: any;
  item: any;
  currencyCode: string;
  customName:any;
  constructor(private sharedNewService: SharedNewService,
    private configService: ConfigService,
    private modalController: ModalController,
    private labelService: LabelService) { }

  async ngOnInit() {
    // this.template = await this.sharedNewService.getTemplateData(this.item.templateId);
    this.currencyCode = this.configService.environment.currencyCode;
  }

  getSelectOptionTxt(section) {
    let txt = '';
    switch(section.multiple) {
      case true:
        txt = `Select up to ${section.options.length} options`;
        break;
      case false:
        txt = `Select 1 out of ${section.options.length} options`;
        break;
    }
    return txt;
  }

  selectOption(sectionIndex, option, optionIndex) {
    option.checked = !option.checked;
    if(!this.template.sections[sectionIndex].multiple) {
      if(option.checked) {
        this.template.sections[sectionIndex].options.forEach((el, index) => {
          if(optionIndex !== index) {
            el.checked = false;
          }
        });
      }
    }
    this.getAddonsPrice();
  } 

  onChangeOption(event, sectionIndex) {
    const optionIndex = event.target.value;
    this.template.sections[sectionIndex].options.forEach((el, index) => {
      if(optionIndex !== index) {
        el.checked = false;
      } else {
        el.checked = true;
      }
    });
    this.getAddonsPrice();
  }

  getSelectedOptions() {
    let selectedOptions = [];
    this.template.sections.forEach(section => {
      let options = section.options.filter(option => option.checked);
      if(section.required && !section.multiple && !options.length) {
        section.options[0].checked = true;
        options = [section.options[0]];
      }
      if(options.length) {
        selectedOptions.push({name: section.name, quantity: this.item.quantity || 1, options});
      }
    });
    if(!selectedOptions.length) {
      selectedOptions.push({name: 'No add-on', quantity: this.item.quantity || 1, options: [{name: 'No add-on', price: 0}]});
    }
    return selectedOptions;
  }

  getAddonsPrice() {
    let totalOptionsPrice = 0;
    let selectedOptions = this.getSelectedOptions();
    selectedOptions.forEach(el => {
      el.options.forEach(option => {
        totalOptionsPrice += (option.price * el.quantity);
      });
    });
    return totalOptionsPrice || 0;
  }

  getActiveSections() {
    const activeSections = this.template.sections.filter(section => section.active);
    return activeSections.length ? activeSections : null;
  }

  addItems() {
    const isValid = this.checkAnyRequiredSection();
    if(!isValid) {
      this.sharedNewService.presentAlert({
        msg: this.labelService.labels['SELECT_ADDON']['select_required_options'],
        backdropDismiss: true,
        page: ''
      });
      return;
    }

    const options = this.getSelectedOptions();

    options.forEach(el => {
      el.options.forEach(option => {
        if(option.name === 'Dressing: Custom-Cut (40rs/kg)') {
          option.name = this.customName;
        }
      });
    });
    
    this.modalController.dismiss({totalPrice: this.getAddonsPrice(), selectedOptions: options, closed: false});
  }

  checkAnyRequiredSection() {
    let isValid = true;
    for (const section of this.template.sections) {
      let options = section.options.filter(option => option.checked);
      if(section.required && section.multiple && !options.length) {
        isValid = false;
        break;
      }
    }
    return isValid;
  }

  closeModal() {
    this.modalController.dismiss({totalPrice: 0, selectedOptions: [], closed: true});
  }

  isCheckboxDisabled(optionIndex, sectionIndex) {
    let isDisabled = false;
    const section =  this.template.sections[sectionIndex];
    if(!section.multiple && !section.required) {
      this.template.sections[sectionIndex].options.forEach((option, index) => {
        if(optionIndex !== index) {
          if(option.checked) {
            isDisabled = true;
          }
        }
      });
    }
    return isDisabled;
  }

}